import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import ReactSlider from "react-slider"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import Trial from "../components/trial"
import linkResolver from "../utils/linkResolver"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import PriceTable from "../components/priceTable"
import LocalizedLink from "../components/localizedLink"
import PricesPlanMobile from "../components/pricesPlanMobile"
import { isExternalLink, isDocumentLink } from "../utils/utils"

const PricePage = ({ data, pageContext: { locale }, location }) => {
  const pricePageData = data.prismic.pricepage
  const pricePageMobileData = data.prismic.pricepageMobile
  const [activeFaq, setActiveFaq] = useState(null)
  const [displayFullInfos, setDisplayFullInfos] = useState(null)
  const [isAnnualSubscription, setIsAnnualSubscription] = useState(true)
  const [numberOfUsers, setNumberOfUsers] = useState(1)
  const essential = 0
  const professional = 1
  const enterprise = 2

  const calcAnnual = (first, additional) => {
    return ((first + (numberOfUsers - 1) * additional) * 12).toFixed(2)
  }
  const calcMonthly = (first, additional) => {
    return (first + (numberOfUsers - 1) * additional).toFixed(2)
  }

  const calcAnnualByPlan = (subscriptionMode, plan) => {
    const prices =
      subscriptionMode === "annual"
        ? pricePageData.annual_subscription_list[plan]
        : pricePageData.monthly_subscription_list[plan]
    return calcAnnual(
      prices.price_for_one_user,
      prices.price_for_additional_user
    )
  }
  const calcMonthlyByPlan = plan => {
    const prices = pricePageData.monthly_subscription_list[plan]
    return calcMonthly(
      prices.price_for_one_user,
      prices.price_for_additional_user
    )
  }

  const yearEssential = calcAnnualByPlan(
    isAnnualSubscription ? "annual" : "monthly",
    essential
  )
  const yearProfessional = calcAnnualByPlan(
    isAnnualSubscription ? "annual" : "monthly",
    professional
  )
  const yearEnterprise = calcAnnualByPlan(
    isAnnualSubscription ? "annual" : "monthly",
    enterprise
  )

  const monthlyEssential = calcMonthlyByPlan(essential)
  const monthlyProfessional = calcMonthlyByPlan(professional)
  const monthlyEnterprise = calcMonthlyByPlan(enterprise)
  return (
    <>
      <SEO
        title={pricePageData.meta_title}
        desc={pricePageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: pricePageData.title,
          subtitle: pricePageData.subtitle,
          image: {
            image: pricePageData.main_image,
            sharp: pricePageData.main_imageSharp,
          },
        }}
      />
      {pricePageData.richtext && (
        <div className="container">
          <div className="columns is-theme2">
            <div className="column is-6">
              RichText.render(pricePageData.richtext, linkResolver)
            </div>
          </div>
        </div>
      )}

      <section className="section is-medium" css={pricingCss}>
        <div className="container">
          <div className="plans">
            <PricesPlanMobile
              planNum={0}
              pricepageMobile={pricePageMobileData}
              priceYear={[yearEssential, yearProfessional, yearEnterprise]}
              priceMonth={[
                monthlyEssential,
                monthlyProfessional,
                monthlyEnterprise,
              ]}
            />
            <div className="columns plan__img is-hidden-touch">
              <div className="column is-3"></div>
              {pricePageData.plans.map((plan, i) => (
                <div key={i} className="column is-3">
                  <div className="box has-text-white has-text-centered">
                    {plan.plan_image && (
                      <figure className="image is-128x128">
                        <Image
                          sharp={plan.plan_imageSharp}
                          image={plan.plan_image}
                        />
                      </figure>
                    )}
                    <h3 className="title is-4 has-text-white has-text-centered is-uppercase has-text-weight-semibold">
                      {RichText.asText(plan.plan_name)}
                    </h3>
                    {plan.plan_description &&
                      RichText.render(plan.plan_description, linkResolver)}
                  </div>
                </div>
              ))}
            </div>
            {/* Number of users */}
            <div className="columns is-hidden-touch my-4">
              <div className="column is-3">
                <h4 className="title is-4 has-text-centered has-text-weight-medium">
                  {RichText.asText(pricePageData.number_of_users)}
                </h4>
              </div>
              <div className="column plan__user is-9">
                {/* <output for="sliderWithValue">50</output> */}
                {/* <input
                  className="slider is-fullwidth is-warning is-circle has-output-tooltip"
                  step="1"
                  min="0"
                  max="100"
                  id="sliderWithValue"
                  type="range"
                /> */}

                <div className="box">
                  <ReactSlider
                    css={reactSliderCss}
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="slider-track"
                    min={1}
                    max={25}
                    onChange={val => setNumberOfUsers(val)}
                    renderThumb={(props, state) => (
                      // className="tag is-size-5 px-5 has-text-white"
                      <div {...props}>
                        <div className="tag is-size-6 px-5 has-text-white">
                          {state.valueNow < 25
                            ? state.valueNow
                            : `${state.valueNow}+`}
                        </div>
                        <div className="thumb-button">
                          <span className="icon-slider_thumb"></span>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            {/* Annual pre-payment subscription */}
            <div className="columns plan__pricing is-hidden-touch">
              <div className="column is-3 has-text-centered">
                <h4 className="title is-5 has-text-weight-medium">
                  {RichText.asText(pricePageData.annual_subscription)}
                </h4>
                <div className="field">
                  <input
                    id="annualSubscription"
                    type="checkbox"
                    name="annualSubscription"
                    className="switch"
                    checked={isAnnualSubscription}
                    onChange={() =>
                      setIsAnnualSubscription(!isAnnualSubscription)
                    }
                  />
                  <label htmlFor="annualSubscription"></label>
                </div>
              </div>
              {pricePageData.annual_subscription_list.map((price, i) => (
                <div
                  key={i}
                  className={`column is-3  ${
                    i === 1 ? "isBorderBold isBorderBoldTop" : ""
                  }`}
                >
                  <div
                    className={`box has-text-centered  ${
                      isAnnualSubscription === true
                        ? "has-background-blue has-text-white"
                        : "has-opacity"
                    }
                    `}
                  >
                    {price.annual_subscription_list_price &&
                      RichText.render(
                        price.annual_subscription_list_price,
                        linkResolver
                      )}
                    <span
                      className={`tag has-text-weight-normal  ${
                        isAnnualSubscription === true
                          ? "has-background-white"
                          : ""
                      }
                    `}
                    >
                      {price.annual_subscription_list_price_aside &&
                        RichText.render(
                          price.annual_subscription_list_price_aside,
                          linkResolver
                        )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {/* Monthly subscription */}
            <div className="columns plan__pricing is-hidden-touch">
              <div className="column is-3 has-text-centered">
                <h4 className="title is-5 has-text-weight-medium">
                  {RichText.asText(pricePageData.monthly_subscription)}
                </h4>
                <div className="field">
                  <input
                    id="annualSubscription"
                    type="checkbox"
                    name="annualSubscription"
                    className="switch"
                    checked={!isAnnualSubscription}
                    onChange={() =>
                      setIsAnnualSubscription(isAnnualSubscription)
                    }
                  />
                  <label htmlFor="annualSubscription"></label>
                </div>
              </div>
              {pricePageData.monthly_subscription_list.map((price, i) => (
                <div
                  className={`column is-3 ${i === 1 ? "isBorderBold " : ""} ${
                    numberOfUsers === 25 ? "isBorderBoldBottom" : ""
                  }`}
                >
                  <div
                    className={`box has-text-centered  ${
                      isAnnualSubscription === false
                        ? "has-background-blue has-text-white"
                        : "has-opacity"
                    }

                    `}
                  >
                    {price.monthly_subscription_list_price &&
                      RichText.render(
                        price.monthly_subscription_list_price,
                        linkResolver
                      )}
                    <span
                      className={`tag has-text-weight-normal  ${
                        isAnnualSubscription === false
                          ? "has-background-white"
                          : ""
                      }
                    `}
                    >
                      {price.monthly_subscription_list_price_aside &&
                        RichText.render(
                          price.monthly_subscription_list_price_aside,
                          linkResolver
                        )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {/* Annual total */}
            <div className="columns plan__pricing is-hidden-touch">
              <div className="column is-3">
                <h4 className="title is-5 has-text-centered has-text-weight-medium">
                  {RichText.asText(pricePageData.annual_total)}
                </h4>
              </div>
              {/* calculated prices here */}
              {numberOfUsers < 25 && (
                <>
                  <div className="column is-3">
                    <div className="box has-text-centered has-text-weight-bold">
                      {yearEssential} €/HT
                    </div>
                  </div>
                  <div
                    className={`column is-3 ${
                      isAnnualSubscription ? "isBorderBold" : "isBorderBold"
                    } `}
                  >
                    <div className="box has-text-centered has-text-weight-bold">
                      {yearProfessional} €/HT
                    </div>
                  </div>
                  <div className="column is-3">
                    <div className="box has-text-centered has-text-weight-bold">
                      {yearEnterprise} €/HT
                    </div>
                  </div>
                </>
              )}
              {numberOfUsers === 25 && (
                <div className="column is-9">
                  <a
                    href={`mailto:contact@monstock.net?subject=Prices`}
                    className="button is-fullwidth is-rounded button__primary has-text-white is-medium"
                  >
                    {pricePageData.contact_us}
                  </a>
                </div>
              )}
            </div>
            {/* Monthly total */}
            {!isAnnualSubscription && (
              <div className="columns plan__pricing is-hidden-touch">
                <div className="column is-3">
                  <h4 className="title is-5 has-text-centered has-text-weight-medium">
                    {RichText.asText(pricePageData.monthly_total)}
                  </h4>
                </div>
                {/* calculated prices here */}
                {numberOfUsers < 25 && (
                  <>
                    <div className="column is-3">
                      <div className="box has-text-centered has-text-weight-bold">
                        {monthlyEssential} €/HT
                      </div>
                    </div>
                    <div className="column is-3 isBorderBold">
                      <div className="box has-text-centered has-text-weight-bold">
                        {monthlyProfessional} €/HT
                      </div>
                    </div>
                    <div className="column is-3">
                      <div className="box has-text-centered has-text-weight-bold">
                        {monthlyEnterprise} €/HT
                      </div>
                    </div>
                  </>
                )}
                {numberOfUsers === 25 && (
                  <div className="column is-9">
                    <a
                      href={`mailto:contact@monstock.net?subject=Prices`}
                      className="button is-fullwidth"
                    >
                      {pricePageData.contact_us}
                    </a>
                  </div>
                )}
              </div>
            )}

            <div className="columns ">
              <div className="column is-3"></div>
              {pricePageData.trial.map((trial, i) => {
                return (
                  <div
                    key={i}
                    className={`column is-3 has-text-centered ${
                      i === 1 && numberOfUsers < 25
                        ? "isBorderBold isBorderBoldBottom"
                        : ""
                    }`}
                  >
                    {trial.button_link && isExternalLink(trial.button_link) && (
                      <a
                        href={trial.button_link.url}
                        className="button button__primary has-text-white is-fullwidth has-text-weight-bold btn__trial is-hidden-touch"
                      >
                        {trial.button_text}
                      </a>
                    )}
                    {trial.button_link && isDocumentLink(trial.button_link) && (
                      <LocalizedLink
                        to={trial.button_link._meta.uid}
                        className="button is-rounded "
                      >
                        {trial.button_text}
                      </LocalizedLink>
                    )}
                  </div>
                )
              })}
            </div>
            <div className="columns my-4">
              <div className="column is-3 is-hidden-touch"></div>
              <div className="column has-text-centered">
                <button
                  onClick={() => setDisplayFullInfos(!displayFullInfos)}
                  className="button is-rounded has-background-white has-text-blue has-text-weight-medium button__gradient-border"
                >
                  {pricePageData.button_full_comparison}
                </button>
              </div>
            </div>
          </div>

          {displayFullInfos && (
            <>
              <div className="box box__full-comparison">
                <PriceTable
                  name={pricePageData.table_basic_title}
                  rows={pricePageData.body_basic}
                />
              </div>
              <div className="box box__full-comparison">
                <PriceTable
                  name={pricePageData.table_inventory_management_title}
                  rows={pricePageData.body_inventory_management}
                />
              </div>
              <div className="box box__full-comparison">
                <PriceTable
                  name={pricePageData.table_user_management_title}
                  rows={pricePageData.body_user_management}
                />
              </div>
              <div className="box box__full-comparison">
                <PriceTable
                  name={pricePageData.table_buying_and_selling_title}
                  rows={pricePageData.body_buying_and_selling}
                />
              </div>
              <div className="box box__full-comparison">
                <PriceTable
                  name={pricePageData.table_connectivity_and_integration_title}
                  rows={pricePageData.body_connectivity_and_integration}
                />
              </div>
              {pricePageData.body_delivery !== null &&
                pricePageData.body_delivery.length !== 0 && (
                  <div className="box box__full-comparison">
                    <PriceTable
                      name={pricePageData.table_delivery_title}
                      rows={pricePageData.body_delivery}
                    />
                  </div>
                )}
              {pricePageData.body_assistance !== null &&
                pricePageData.body_assistance.length !== 0 && (
                  <div className="box box__full-comparison">
                    <PriceTable
                      name={pricePageData.table_assistance_title}
                      rows={pricePageData.body_assistance}
                    />
                  </div>
                )}
              {pricePageData.body_data_hosting !== null &&
                pricePageData.body_data_hosting.length !== 0 && (
                  <div className="box box__full-comparison">
                    <PriceTable
                      name={pricePageData.table_data_hosting_title}
                      rows={pricePageData.body_data_hosting}
                    />
                  </div>
                )}
              {pricePageData.body_other !== null &&
                pricePageData.body_other.length !== 0 && (
                  <div className="box box__full-comparison">
                    <PriceTable
                      name={pricePageData.table_other_title}
                      rows={pricePageData.body_other}
                    />
                  </div>
                )}

              <div className="columns">
                <div className="column is-hidden-touch"></div>
                {pricePageData.trial.map((trial, i) => {
                  return (
                    <div
                      key={i}
                      className="column has-text-centered box__full-comparison-trial"
                    >
                      {trial.button_link && isExternalLink(trial.button_link) && (
                        <a
                          href={trial.button_link.url}
                          className="button button__primary has-text-white is-fullwidth has-text-weight-bold btn__trial"
                        >
                          {trial.button_text}
                        </a>
                      )}
                      {trial.button_link && isDocumentLink(trial.button_link) && (
                        <LocalizedLink
                          to={trial.button_link._meta.uid}
                          className="button is-rounded"
                        >
                          {trial.button_text}
                        </LocalizedLink>
                      )}
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </section>

      <section className="section is-theme2" css={proServicesCss}>
        <div className="container has-text-centered pb-6">
          <h3 className="title is-2 has-text-weight-semibold py-6">
            {RichText.asText(pricePageData.optional_title)}
          </h3>
          <div className="columns is-centered is-multiline has-background-white">
            {pricePageData.optional.map((option, i) => (
              <div key={i} className="column is-half py-6">
                <h5 className="title is-5 has-text-weight-semibold">
                  {RichText.asText(option.optional_list_title)}
                </h5>
                {option.optional_list_description &&
                  RichText.render(
                    option.optional_list_description,
                    linkResolver
                  )}
              </div>
            ))}
            <div className="column is-full py-6 is-themeLightPink">
              <h4 className="title is-4 has-text-weight-semibold">
                {RichText.asText(pricePageData.optional_footer_title)}
              </h4>

              {pricePageData.optional_button_link &&
                isExternalLink(pricePageData.optional_button_link) && (
                  <a
                    href={pricePageData.optional_button_link.url}
                    className="button is-rounded button__primary has-text-white is-medium px-6"
                  >
                    {pricePageData.optional_button_text}
                  </a>
                )}
              {pricePageData.optional_button_link &&
                isDocumentLink(pricePageData.optional_button_link) && (
                  <LocalizedLink
                    to={pricePageData.optional_button_link._meta.uid}
                    className="button is-rounded button__primary has-text-white is-medium px-6"
                  >
                    {pricePageData.optional_button_text}
                  </LocalizedLink>
                )}
            </div>
          </div>
        </div>
      </section>

      <section className="section" css={faqCss}>
        <div className="container has-text-centered">
          {pricePageData.faq_button_link && (
            <a
              href={pricePageData.faq_button_link.url}
              className="button is-rounded button__primary has-text-white is-medium px-6"
            >
              {pricePageData.faq_button_text}
            </a>
          )}
        </div>
      </section>

      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
    </>
  )
}

export default PricePage

PricePage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const PriceQuery = graphql`
  query PriceQuery($uid: String!, $locale: String!) {
    prismic {
      pricepageMobile: pricepage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        richtext
        main_image
        main_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        plans {
          plan_image
          plan_name
          plan_description
        }

        number_of_users
        annual_subscription
        annual_subscription_list {
          annual_subscription_list_price
          annual_subscription_list_price_aside
          price_for_one_user
          price_for_additional_user
        }
        monthly_subscription
        monthly_subscription_list {
          monthly_subscription_list_price
          monthly_subscription_list_price_aside
          price_for_one_user
          price_for_additional_user
        }
        annual_total
        monthly_total
        button_full_comparison
      }

      pricepage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        richtext
        main_image
        main_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        plans {
          plan_image
          plan_name
          plan_description
        }

        number_of_users
        annual_subscription
        annual_subscription_list {
          annual_subscription_list_price
          annual_subscription_list_price_aside
          price_for_one_user
          price_for_additional_user
        }
        monthly_subscription
        monthly_subscription_list {
          monthly_subscription_list_price
          monthly_subscription_list_price_aside
          price_for_one_user
          price_for_additional_user
        }
        annual_total
        monthly_total
        button_full_comparison

        table_basic_title
        body_basic {
          ... on PRISMIC_PricepageBody_basicRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }

        table_inventory_management_title
        body_inventory_management {
          ... on PRISMIC_PricepageBody_inventory_managementRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        table_user_management_title
        body_user_management {
          ... on PRISMIC_PricepageBody_user_managementRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        table_buying_and_selling_title
        body_buying_and_selling {
          ... on PRISMIC_PricepageBody_buying_and_sellingRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        table_services_title
        body_services {
          ... on PRISMIC_PricepageBody_servicesRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        table_connectivity_and_integration_title
        body_connectivity_and_integration {
          ... on PRISMIC_PricepageBody_connectivity_and_integrationRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        table_delivery_title
        body_delivery {
          ... on PRISMIC_PricepageBody_deliveryRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        table_assistance_title
        body_assistance {
          ... on PRISMIC_PricepageBody_assistanceRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        table_data_hosting_title
        body_data_hosting {
          ... on PRISMIC_PricepageBody_data_hostingRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        table_other_title
        body_other {
          ... on PRISMIC_PricepageBody_otherRow {
            primary {
              row_name
            }
            fields {
              row_value_bool
              row_value_text
            }
          }
        }
        contact_us
        optional_title
        optional_button_text
        optional_footer_title
        optional_button_link {
          _linkType
          ... on PRISMIC__Document {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC__ExternalLink {
            url
          }
        }

        optional {
          optional_list_title
          optional_list_description
        }
        trial {
          button_id
          button_text
          button_link {
            _linkType
            ... on PRISMIC__Document {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__ExternalLink {
              url
            }
          }
        }
        faq_button_text
        faq_button_link {
          ... on PRISMIC__ExternalLink {
            url
          }
        }
      }
      ...Trial
    }

    site {
      ...SiteInformation
    }
  }
`

const pricingCss = css`

  .switch[type="checkbox"] + label::before,
  .switch[type="checkbox"] + label:before {
    border-radius: 50px;
  }

  .switch[type="checkbox"] + label::after,
  .switch[type="checkbox"] + label:after {
    border-radius: 50%;
  }

  .switch[type="checkbox"]:checked + label::before,
  .switch[type="checkbox"]:checked + label:before {
    background-image: ${colors.orangeGradient};
    background-color: #fff;
    border: 1px solid transparent;
  }

  .switch[type="checkbox"]:checked + label::after,
  .switch[type="checkbox"]:checked + label:after {
    background-color: #fff;
  }

  .switch[type="checkbox"] + label::before,
  .switch[type="checkbox"] + label:before {
    background-color: #fff;
    border: 1px solid ${colors.blue};
  }

  .switch[type="checkbox"] + label::after,
  .switch[type="checkbox"] + label:after {
    background-color: ${colors.blue};
  }

  .plan__img {
    .column {
      display: flex;
    }
    .column:nth-child(even) .box {
      background-color: ${colors.blue};
    }
    .column:nth-child(odd) .box {
      background: rgb(255, 137, 137);
      background: radial-gradient(
        circle at top center,
        rgba(255, 137, 137, 1) 0%,
        rgba(238, 98, 72, 1) 50%,
        rgba(222, 78, 78, 1) 100%
      );
    }
    .box {
      position: relative;
      padding: 80px 20px 40px;
      border-radius: 10.8px;
    }
    .image {
      position: absolute;
      top: -125px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .btn__trial {
    height: 70px;
    font-size: 1.125rem;
    border-radius: 10px;
  }

  .plan__pricing,
  .plan__user {
    .box {
      border: 1px solid ${colors.lightgray};
      box-shadow: none;
    }
  }

  .plan__pricing {
    p {
      line-height: 1;
    }
    .box {
      & > p {
        font-size: 1.125rem;
        line-height: inherit;
      }
    }
    .column:first-of-type {
      border-top: 1px dashed ${colors.borderForm};
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .tag {
      background-color: ${colors.blueLightOne};
      width: 100%;
      padding: 20px;
      margin-top: 20px;
      font-size: 0.9375rem;
      white-space: normal;
    }
  }

  .box__full-comparison {
    box-shadow: none;
  }

  .plans__mobile {
    &:nth-of-type(odd)
      .plans__mobile-type.is-hidden-desktop
      .plan__mobile-header
      .box {
      background-color: ${colors.blue};
    }
    &:nth-of-type(even)
      .plans__mobile-type.is-hidden-desktop
      .plan__mobile-header
      .box {
      background: rgb(255, 137, 137);
      background: radial-gradient(
        circle at top center,
        rgba(255, 137, 137, 1) 0%,
        rgba(238, 98, 72, 1) 50%,
        rgba(222, 78, 78, 1) 100%
      );
    }
  }

  @media (max-width: 1023px) {
    .box__full-comparison-trial:nth-of-type(2),
    .box__full-comparison-trial:nth-of-type(3) {
      display: none;
    }
    .plans {
      padding-top: 3rem;
    }
  }

  @media (min-width: 1024px) {
    .plans {
      padding-top: 6rem;
    }
    .isBorderBold {
      border-left: 4px solid ${colors.orange};
      border-right: 4px solid ${colors.orange};
      &.isBorderBoldTop {
        border-top: 4px solid ${colors.orange};
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
      }
      &.isBorderBoldBottom {
        border-bottom: 4px solid ${colors.orange};
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
      }
    }
  }
`

const proServicesCss = css`
  .columns:first-of-type {
    border: 1px solid ${colors.lightgray};
    -webkit-box-shadow: 0px 2px 4px 0px ${colors.lightgray};
    -moz-box-shadow: 0px 2px 4px 0px ${colors.lightgray};
    box-shadow: 0px 2px 4px 0px ${colors.lightgray};
  }

  .column:nth-child(even) {
    border-width: 0px 0px 1px 0px;
    border-color: ${colors.lightgray};
    border-style: solid;
  }

  .column:nth-child(odd) {
    border-width: 0px 1px 1px 0px;
    border-color: ${colors.lightgray};
    border-style: solid;
  }

  .column:last-of-type {
    border: 0;
  }

  @media (max-width: 768px) {
    .column:nth-child(odd) {
      border-width: 0px 0px 1px 0px;
    }
  }
`

const faqCss = css``

const reactSliderCss = css`
  &.horizontal-slider {
    background: ${colors.blueLightOne};
    padding: 5px;
    border-radius: 10px;
  }
  .tag {
    position: relative;
    background: #94a2cd;
    border-radius: 0.4em;
    margin-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #94a2cd;
      border-bottom: 0;
      margin-left: -5px;
      margin-bottom: -5px;
    }
  }

  .example-thumb {
    bottom: -8px;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
  }

  .thumb-button {
    height: 25px;
    line-height: 28px;
    width: 25px;
    text-align: center;
    background-color: #fff;
    color: ${colors.blueHr};
    border-radius: 50%;
    cursor: grab;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  }

  .slider-track-0 {
    top: 0;
    bottom: 0;
    background: rgb(255, 159, 110);
    background: linear-gradient(
      90deg,
      rgba(255, 159, 110, 1) 70%,
      rgba(168, 189, 255, 1) 100%
    );
    border-radius: 999px;
  }
`
