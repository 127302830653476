import React from "react"
import { RichText } from "prismic-reactjs"

import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"

function priceTable({ name, rows }) {
  const check = <span className="icon-check"></span>
  const cross = <span className="icon-cross"></span>
  return (
    <>
      <div className="price__table" css={priceTableCss}>
        <div className="columns is-mobile">
          <div className="column is-3"></div>
          <div className="column has-text-centered price__table-title">
            <h3 className="title is-size-5-desktop is-size-6-touch has-text-weight-medium">{RichText.asText(name)}</h3>
          </div>
        </div>
      {rows.map((row, i) => (
        <div key={i} className="columns is-vcentered price__table-body is-mobile">
          <div className="column is-3">
            <h4 className="is-size-6 is-size-7-touch">{RichText.asText(row.primary.row_name)}</h4>
          </div>
          {row.fields.map((field, y) => (
            <div key={y} className="column is-3 has-text-centered has-text-green is-size-5-desktop is-size-7-touch has-text-weight-medium">
              {field.row_value_bool
                ? check
                : field.row_value_text
                ? field.row_value_text
                : cross}
            </div>
          ))}
        </div>
      ))}
      </div>
    </>
  )
}

export default priceTable

const priceTableCss = css `
  .has-text-green {
    color: #61B51D;
  }

  .icon-cross {
    color: #C2C2C2;
  }

  .box {
    box-shadow: none;
  }

  .price__table-title {
    background: ${colors.blueLightOne};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    z-index: 1000;
  }

  .price__table-body {
    border-top: 1px solid ${colors.lightgray};
    border-left: 1px solid ${colors.lightgray};
    border-right: 1px solid ${colors.lightgray};

    flex-wrap: wrap;
    align-items: stretch;

    &:last-of-type {
      border-bottom: 1px solid ${colors.lightgray};
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .column:nth-child(3) {
      background: linear-gradient(90deg, rgba(253,117,67,0.05) 0%, rgba(222,78,78,0.05) 100%);
    }

    .column:nth-child(1) {
      border-right: 1px solid ${colors.lightgray};
    }

  }

  @media (max-width: 500px) {
    .column {
      padding: .5rem;
    }

    .price__table-body {
      h4 {
        font-size: .55rem !important;
      }
      .column {
        word-break: break-word;
      }
    }
  }
`
